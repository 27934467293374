import { useEffect, useState, useMemo, useRef } from 'react'
import PropTypes from 'prop-types'
import { useAtom } from 'jotai'
import { VStack, Box, Divider, Center } from '@chakra-ui/react'

import { Config } from '@!/config/config'
import { buildUrlString, isBlank } from '@!/Util'
import { breadcrumbAtom } from '@!/atoms/breadcrumbAtom'
import { SearchForm } from '@!/Store/Common/containers'
import { PointBackBanner, MembershipRegistrationBanner } from '@!/components/Banner'
import { BookInfo } from '@!/Store/BookDetail/components/BookInfo'
import { BookList } from '@!/Store/Common/components/BookList'
import { Paginate } from '@!/components/Paginate'
import { ScrollableFeatureList } from '@!/Store/Common/components'
import { userHashAtom } from '@!/atoms/userInfoAtom'
import { useCheckPurchasedBooks } from '@!/Hooks'

const StoreBookDetail = ({ book, currentTime }) => {
  const [userHash] = useAtom(userHashAtom)
  const [volumes, setVolumes] = useState(book.books)
  const [totalCount, setTotalCount] = useState(0)
  const [, setBreadcrumb] = useAtom(breadcrumbAtom)
  const volumesBox = useRef(null)
  const volumesPerPage = Config.volumesPerPage

  // book.booksにはbookも含まれている
  const distributionIds = useMemo(() => book.books.map((volume) => volume.distribution_id), [book.books])
  const { data: purchasedVolumesIds, isSuccess: isVolumesSuccess } = useCheckPurchasedBooks(userHash, distributionIds)
  const [currentBook, setCurrentBook] = useState({ ...book, purchased: false })

  useEffect(() => {
    // パンくずのセット
    setBreadcrumb([
      { name: 'マンガBANGブックス', ref: '/' },
      { name: book.genre_name, ref: buildUrlString(`/store/search/category/${book.genre_name}`) },
      { name: book.publisher_name, ref: buildUrlString(`/store/search/publisher/${book.publisher_name}`) },
      { name: book.title_name, ref: buildUrlString('/store/books', { book_title_id: book.title_id }) },
      { name: book.name },
    ])
  }, [])

  useEffect(() => {
    if (!isVolumesSuccess) return

    // distribution_idがpurchasedVolumesIdsに存在するかどうかをpurchasedに保存
    setCurrentBook({ ...book, purchased: purchasedVolumesIds.some((purchasedBookId) => purchasedBookId == book.distribution_id) })

    // distribution_idがpurchasedVolumesIdsに存在するかどうかをpurchasedに保存してlistItemsを更新
    setVolumes(
      book.books.map((volume) => {
        return { ...volume, purchased: purchasedVolumesIds.some((purchasedBookId) => purchasedBookId == volume.distribution_id) }
      }),
    )
  }, [isVolumesSuccess])

  useEffect(() => {
    setTotalCount(volumes.length)
    setCurrentVolumes(volumes.slice(0, volumesPerPage))
  }, [volumes])

  const [currentVolumes, setCurrentVolumes] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [volumeOffset, setVolumeOffset] = useState(0)

  useEffect(() => {
    const endOffset = volumeOffset + volumesPerPage
    setCurrentVolumes(volumes.slice(volumeOffset, endOffset))
  }, [volumeOffset])

  const handlePageClick = (page) => {
    const newOffset = ((page - 1) * volumesPerPage) % volumes.length
    setVolumeOffset(newOffset)
    setCurrentPage(page)
    volumesBox.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
  }

  const canRender = useMemo(() => (!!userHash && isVolumesSuccess) || !userHash, [userHash, isVolumesSuccess])

  return (
    <>
      {canRender ? (
        <Box>
          <PointBackBanner />
          <SearchForm />
          <Divider mt={2} mb={2} />
          <VStack spacing={0}>
            <BookInfo book={currentBook} volumes={volumes} />
            <Divider borderBottomWidth="12px" mt="0" />
            <Box p={4} w="100%" boxSizing="border-box" ref={volumesBox}>
              {!isBlank(currentVolumes) ? <BookList book={currentBook} volumes={currentVolumes} currentTime={currentTime} /> : null}
            </Box>
            <Box p={4} w="100%" boxSizing="border-box">
              <Center>
                <Paginate currentPage={currentPage} perPage={volumesPerPage} totalCount={totalCount} onPageChange={handlePageClick} />
              </Center>
            </Box>
            <Box w="100%" boxSizing="border-box">
              {!isBlank(currentBook.store_features)
                ? currentBook.store_features.map((feature, index) => (
                    <Box key={index} mb={8}>
                      <ScrollableFeatureList key={feature.pathname} title={feature.title} pathname={feature.pathname} books={feature.book_titles} />
                    </Box>
                  ))
                : null}
            </Box>
            {!userHash && <MembershipRegistrationBanner p={4} pt={0} />}
          </VStack>
        </Box>
      ) : null}
    </>
  )
}

StoreBookDetail.propTypes = {
  book: PropTypes.object.isRequired,
  currentTime: PropTypes.string.isRequired,
}

export default StoreBookDetail
